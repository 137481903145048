<template>
  <el-dialog
    title="文章"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="所属类型" prop="aTypeID">
        <el-select
          v-model="temp.aTypeID"
          placeholder="请选择"
          @change="change_types"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.id"
            :label="item.tName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="aTitle">
        <el-input v-model="temp.aTitle"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="aContent">
        <tinymce v-model="temp.aContent" :height="500" />
      </el-form-item>
      <el-form-item label="上传视频" prop="aPath">
        <el-upload
          class="avatar-uploader"
          :action="actionUrl"
          :data="actionData"
          :on-success="uploadSuccess"
          :show-file-list="false"
        >
          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
          <video v-if="imageUrl" :src="imageUrl"></video>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import UploadSign from "@/utils/upload-sign";
export default {
  components: { Tinymce },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      this.getList();
    },
    row_type(val) {
      Object.assign(this.temp, val);
    },
  },
  data() {
    return {
      imageUrl: "",
      actionUrl: process.env.VUE_APP_BASE_URL + "upload.php",
      actionData: Object.assign({ op: "files_upload" }, UploadSign),

      optionsList: [],
      dialogVisible: false,
      temp: {
        id: "",
        aTypeID: "",
        aTitle: "",
        aContent: "",
        aPath: "",
      },
      rules: {
        aTypeID: [
          {
            required: true,
            message: "所属类型必填",
            trigger: "blur",
          },
        ],
        aTitle: [
          {
            required: true,
            message: "标题必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      this.imageUrl = response.path;
      this.temp.aPath = response.path;
    },
    change_types(val) {
      this.temp.nTypeID = val;
    },
    getList() {
      this.$api.article_types_list_all({}).then((res) => {
        this.optionsList = res.data.rows;
      });
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.articles_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
